<template>
    <b-row>
        <b-overlay :show="unitLoad">
            <b-col xl="12" lg="12" sm="12">
                <ValidationObserver ref="form2" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                        <b-row>
                            <b-col sm="6">
                                <ValidationProvider name="Organization Name (En)" vid="org_name_en" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="org_name_en">
                                        <template v-slot:label>
                                            {{ $t('globalTrans.org_name') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input v-model="detail_obj.org_name_en" :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <ValidationProvider name="Organization Name (Bn)" vid="org_name_bn" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="org_name_bn">
                                        <template v-slot:label>
                                            {{ $t('globalTrans.org_name') + ' ' + $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input v-model="detail_obj.org_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <ValidationProvider name="Designation (En)" vid="designation_en" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="designation_en">
                                        <template v-slot:label>
                                            {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.en') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input v-model="detail_obj.designation_en"
                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required" v-slot="{ errors }">
                                    <b-form-group label-for="designation_bn">
                                        <template v-slot:label>
                                            {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input v-model="detail_obj.designation_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6">
                                <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
                                    <b-form-group label-for="email">
                                        <template v-slot:label>
                                            {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input id="org_bn" type="email"
                                            v-model="detail_obj.email"
                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mt-3 pt-2">
                                    <b-button type="submit" class="mr-1" size="sm" variant="primary"><i class="ri-add-fill" style="vertical-align: bottom;"></i> {{ editIndex !== '' ? $t('globalTrans.update') : $t('globalTrans.add')}}</b-button>
                                    <b-button type="button" v-if="editIndex !== ''" size="sm" variant="danger" @click="cancelItem()"><i class="ri-close-fill" style="vertical-align: bottom;"></i> {{ $t('globalTrans.cancel') }}</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </b-col>
            <hr>
            <b-col xl="12" lg="12" sm="12">
                <p class="text-right" v-if="edit_mode">[Note: Selected items will be counted as resend email.]</p>
                <b-table-simple bordered>
                    <thead class="">
                        <tr class="text-center">
                            <th>{{ $t('globalTrans.sl_no') }}</th>
                            <th scope="col">{{ $t('globalTrans.org_name') }}</th>
                            <th scope="col">{{ $t('globalTrans.designation') }}</th>
                            <th scope="col">{{ $t('globalTrans.email') }}</th>
                            <th v-if="edit_mode" scope="col">{{ $t('globalTrans.status') }}</th>
                            <th width="15%">{{ $t('globalTrans.action') }}</th>
                        </tr>
                    </thead>
                    <b-tbody>
                        <b-tr v-for="(item, index) in mails" :key="index">
                            <b-td class="text-center">
                                <b-form-checkbox
                                    :id="'checkbox-1'+index"
                                    v-model="item.checked"
                                    name="checkbox-1"
                                    :value=1
                                    :unchecked-value=2
                                >
                                {{ $n(index + 1) }}
                                </b-form-checkbox>
                            </b-td>
                            <b-td>{{ $i18n.locale === 'en' ? item.org_name_en : item.org_name_bn }}</b-td>
                            <b-td>{{ $i18n.locale === 'en' ? item.designation_en : item.designation_bn }}</b-td>
                            <b-td>{{ item.email }}</b-td>
                            <b-td class="text-center" v-if="edit_mode">
                                <span v-if="item.is_sent == 1">{{ $t('globalTrans.success') }}</span>
                                <span v-else-if="item.is_sent == 2">{{ $t('globalTrans.failed') }}</span>
                                <span v-else>{{ $t('globalTrans.pending') }}</span>
                            </b-td>
                            <b-td class="text-center">
                                <b-button @click="editItem(item, index)" title="Edit" class="btn btn-success mr-1" size="sm" style="padding: 0rem 0.4rem !important;"><i class="ri-pencil-fill m-0"></i></b-button>
                                <b-button type="button" @click="deleteItem(index)" title="Delete" class="btn btn-danger" size="sm" style="padding: 0rem 0.4rem !important;"><i class="ri-delete-bin-2-line"></i></b-button>
                            </b-td>
                        </b-tr>
                        <b-tr v-if="mails.length <= 0">
                            <td colspan="6" class="text-center">
                                <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                            </td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col class="text-right">
                <b-button type="button" @click="saveUpdate()" variant="success" class="mr-1 btn-sm">{{ edit_mode ? $t('globalTrans.resend') : $t('globalTrans.send') }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-mail')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
        </b-overlay>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { prepareCircularMailSend, prepareCircularMailList } from '../../api/routes'
export default {
  name: 'MailModal',
  props: ['id'],
  data () {
    return {
      valid: null,
      errors: [],
      editIndex: '',
      unitLoad: false,
      detail_obj: {
        org_name_en: '',
        org_name_bn: '',
        designation_en: '',
        designation_bn: '',
        email: '',
        is_sent: 0,
        checked: 1
      },
      fair_info: {},
      mails: [],
      edit_mode: false
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getStoredData()
        this.fair_info.circular_id = tmp.id
        this.fair_info.approved_fair_mail = true
        this.loadMail()
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        //
      }
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getStoredData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    cancelItem () {
        this.detail_obj = {}
        this.editIndex = ''
        this.$refs.form2.reset()
    },
    editItem (item, index) {
        this.detail_obj = Object.assign({}, item)
        this.editIndex = index
    },
    deleteItem (index) {
        this.mails.splice(index, 1)
    },
    async addItem () {
        let result = []
        if (this.editIndex !== '') {
            const editList = [...this.mails]
            editList.splice(parseInt(this.editIndex), 1)
            result = [...editList]
        } else {
            result = this.mails
        }
        const tmpEmail = this.detail_obj.email
        const newData = result.find(item => item.email === tmpEmail)
        if (typeof newData === 'undefined') {
                if (this.editIndex !== '') {
                    this.mails[parseInt(this.editIndex)].org_name_en = this.detail_obj.org_name_en
                    this.mails[parseInt(this.editIndex)].org_name_bn = this.detail_obj.org_name_bn
                    this.mails[parseInt(this.editIndex)].designation_en = this.detail_obj.designation_en
                    this.mails[parseInt(this.editIndex)].designation_bn = this.detail_obj.designation_bn
                    this.mails[parseInt(this.editIndex)].email = this.detail_obj.email
                    this.mails[parseInt(this.editIndex)].is_sent = this.detail_obj.is_sent
                    this.mails[parseInt(this.editIndex)].checked = this.detail_obj.checked
                } else {
                    if (this.edit_mode) {
                        this.detail_obj.checked = 1
                    }
                    this.mails.push(JSON.parse(JSON.stringify(this.detail_obj)))
                }
                this.detail_obj = {
                    org_name_en: '',
                    org_name_bn: '',
                    designation_en: '',
                    designation_bn: '',
                    email: '',
                    is_sent: 0,
                    checked: 1
                }
                this.editIndex = ''
        } else {
            this.$toast.error({
                title: '!',
                message: 'Item already added',
                color: '#ee5253'
            })
        }
        this.$nextTick(() => {
            this.$refs.form2.reset()
        })
    },
    async loadMail () {
        this.unitLoad = true
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, prepareCircularMailList + '/' + this.id)
        if (result.success) {
            if (result.data.length) {
                this.mails = result.data.map(el => {
                    return Object.assign(el, {}, { checked: el.is_sent === 2 ? 1 : 2 })
                })
                this.edit_mode = true
            } else {
                this.mails = []
                this.edit_mode = false
            }
            this.unitLoad = false
        } else {
            this.mails = []
            this.unitLoad = false
        }
    },
    async saveUpdate () {
        if (!this.mails.length) {
            return this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: 'Add at least one item!',
                color: 'red'
            })
        }
        this.unitLoad = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        this.fair_info.mails = this.mails
        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, prepareCircularMailSend, this.fair_info)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$toast.success({
                title: 'Success',
                message: result.message,
                color: '#D6E09B'
            })
            this.unitLoad = false
            this.$bvModal.hide('modal-mail')
        } else {
            this.unitLoad = false
            this.$refs.form2.setErrors(result.errors)
        }
    }
  }
}
</script>
<style scoped>
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table-bordered tbody th, .table-bordered tbody td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}
</style>
